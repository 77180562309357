<template>
<div class=" wrapper" v-loading.fullscreen.lock="loading" :element-loading-text="$t('connecting')" element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.8)">
    <div class="w-full wp-body" style="height:100%;">
        <div class="w-full flex   pr " style="height:100%;">
            <div class="flex user-show pr" style="margin-top:20px;">
                <div class="user-left ">

                    <div class="w-full flex justify-center">
                        <div class="user-img">
                            <div class="img-content">
                                <img :src="img ? img : require(`@/assets/img/icons/freeuser.svg`)" alt="">
                            </div>
                            <div class="change-img">
                                <button>
                                    <div v-if="change" style="width:18px; height:14px">
                                        <img src="@/assets/img/icons/online.svg" alt="">
                                    </div>

                                    <img src="@/assets/img/icons/edit-img.svg" alt="" v-else>
                                    <input type="file" accept="image/*" :disabled="change" @click="uploadImage($event)">
                                </button>
                            </div>

                        </div>
                    </div>
                    <div class="user-names" v-if="change">
                        <p class="name">{{obj.profile_for_user.name}} {{obj.profile_for_user.surname}} {{obj.profile_for_user.middlename}} </p>
                        <div class="login-name">
                            <p>/{{me.login_name}}</p>
                        </div>
                        <div class="flex flex-col gap-1 mt-5">
                            <button class="button purple flex items-center text-xs" @click="LogModal=true"><i class="el-icon-user mr-2" style="font-size:14px;"></i> {{$t('reset_login')}}</button>
                            <button class="button purple flex items-center text-xs" @click="PasModal=true"> <i class="el-icon-lock mr-2" style="font-size:14px;"></i> {{$t('reset_password')}}</button>
                        </div> 
                    </div>
                </div>
                <div class="user-right">
                    <h1 class="title-h">{{ change ? $t('own_data') : $t('own_data_change') }}</h1>
                    <el-form :model="obj" :rules="ObjRules" ref="userform" class="demo-ruleForm">
                        <div class="w-full pt-8">
                            <el-row :gutter="24" class="flex items-center user-input-item">
                                <el-col :md="8">
                                    <p class="el-form-item fw-600">{{$t('first_name')}}:</p>
                                </el-col>
                                <el-col :md="16">
                                    <el-form-item label="" label-width="0" prop="profile_for_user.name">
                                        <el-input :disabled="change" :placeholder="$t('first_name')" v-model="obj.profile_for_user.name"></el-input>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row :gutter="24" class="  flex items-center user-input-item">
                                <el-col :md="8">
                                    <p class="el-form-item fw-600">{{$t('last_name')}}:</p>
                                </el-col>
                                <el-col :md="16">
                                    <el-form-item label="" label-width="0" prop="profile_for_user.surname">
                                        <el-input :disabled="change" :placeholder="$t('last_name')" v-model="obj.profile_for_user.surname"></el-input>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row :gutter="24" class="  flex items-center user-input-item">
                                <el-col :md="8">
                                    <p class="el-form-item fw-600">{{$t('mid_name')}}:</p>
                                </el-col>
                                <el-col :md="16">
                                    <el-form-item label="" label-width="0" prop="profile_for_user.middlename">
                                        <el-input :disabled="change" :placeholder="$t('mid_name')" v-model="obj.profile_for_user.middlename"></el-input>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row :gutter="24" class="  flex items-center user-input-item">
                                <el-col :md="8">
                                    <p class="fw-600 el-form-item" style="">{{$t('phone_munber')}}:</p>
                                </el-col>
                                <el-col :md="16">
                                    <el-form-item label="" label-width="0" prop="profile_for_user.phone_number">
                                        <el-input :disabled="change" v-mask="'+998 ##-###-##-##'" :placeholder="$t('phone_munber')" v-model="obj.profile_for_user.phone_number"></el-input>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row :gutter="24" class="mb-2 flex items-center user-input-item">
                                <el-col :md="8">
                                    <p class="el-form-item fw-600">{{$t('user_email')}}:</p>
                                </el-col>
                                <el-col :md="16" v-if="obj.profile_for_user.email || !change ">
                                    <el-form-item label="" label-width="0" prop="profile_for_user.email">
                                        <el-input :disabled="change" placeholder="test@test.uz" v-model="obj.profile_for_user.email"></el-input>
                                    </el-form-item>
                                </el-col>

                                <el-col :md="16" v-else>
                                    ***
                                </el-col>
                            </el-row>

                            <el-row :gutter="24" class="mb-2 flex items-center user-input-item" v-if="change">
                                <el-col :md="24">
                                    <div class="border-div">
                                        <div></div>
                                    </div>
                                </el-col>
                            </el-row>
                            <el-row :gutter="24" class="mb-2 flex items-center user-input-item" v-if="change">
                                <el-col :md="8">
                                    <p class="mb-1 fw-600">{{$t('login_name')}}:</p>
                                </el-col>
                                <el-col :md="16">
                                    <p>{{me.login_name}}</p>
                                </el-col>
                            </el-row>
                            <el-row :gutter="24" class="mb-2 flex items-center user-input-item" v-if="change">
                                <el-col :md="8">
                                    <p class="mb-1 fw-600">{{$t('organisation_name')}}:</p>
                                </el-col>
                                <el-col :md="16">
                                    <p v-if="me.organization">{{me.organization.name_cyr}}</p>
                                </el-col>
                            </el-row>
                            <div class="w-full flex mt-8">
                                        <button class="button dark-blue" type="button" @click="change=false" v-if="change"> <i class="el-icon-edit mr-1"></i> {{$t('change')}}</button>
                                        <button class="button dark-blue" type="button" @click="save()" v-else> <i class="el-icon-document-checked mr-1"></i>{{$t('save')}}</button>
                                    </div>
                        </div>
                    </el-form>
                </div>
            </div>
            <div class="bg1">
                <img width="100%" src="@/assets/img/building.svg" alt="AA">
            </div>
        </div>

    </div>
    <div class="modal" v-if="PasModal">
        <div class="modal-cover"></div>
        <div class="modal-item" style="max-width:500px;">
            <div class="flex justify-between">
                <p class="modal-title">{{$t('reset_password')}}</p>
                <button style="font-size:16px; " @click="PasModal=false"><i class="el-icon-close" style="font-weight:600;"></i> </button>
            </div>
            <el-form :model="password" :rules="PasswordRules" ref="passwordform" class="demo-ruleForm">
                <div class="w-full modal-inputes">
                    <p class="modal-second-title">{{$t('new_password')}}</p>
                    <el-form-item  prop="password">
                        <el-input type="password" show-password :placeholder="$t('new_password')" v-model="password.password"></el-input>
                    </el-form-item>
                </div>
                <div class="w-full modal-inputes">
                    <p class="modal-second-title"> {{$t('replay_password')}} </p>
                    <el-form-item  prop="password_confirmation">
                        <el-input type="password" show-password :placeholder="$t('replay_password')" v-model="password.password_confirmation"></el-input>
                    </el-form-item>
                </div>
            </el-form>
            <div class="w-full modal-inputes flex justify-end">
                <button @click="changePas()" type="button"> <i class="el-icon-document-checked mr-2"></i> {{$t('save')}}</button>
            </div>
        </div>
    </div>
    <div class="modal" v-if="LogModal">
        <div class="modal-cover"></div>
        <div class="modal-item" style="max-width:500px;">
            <div class="flex justify-between">
                <p class="modal-title">{{$t('reset_login')}}</p>
                <button style="font-size:16px; " @click="LogModal=false"><i class="el-icon-close" style="font-weight:600;"></i> </button>
            </div>
            <div class="w-full modal-inputes">
                <el-form :model="login_name" :rules="LoginRules" ref="loginform" class="demo-ruleForm">
                    <p class="modal-second-title">{{ $t('new_login_name') }}</p>
                    <el-form-item label="" label-width="0" prop="login_name" :class="errors.login_have ?'is-error':''">
                        <el-input :placeholder="$t('new_login_name')" v-model="login_name.login_name" @input="errors.login_have=false"></el-input>
                        <div class="el-form-item__error" v-if="errors.login_have"> {{$t('exist_login')}} </div>
                    </el-form-item>
                </el-form>
            </div>

            <div class="w-full modal-inputes flex justify-end">
                <button type="button" @click="changeLog"> <i class="el-icon-document-checked mr-2"></i>{{ $t('save')}}</button>

            </div>
        </div>

    </div>
</div>
</template>

<script>
const toBase64 = (file) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });
export default {
    data() {
        var validatePass = (rule, value, callback) => {
            if (value === '') {
                callback(new Error(this.$t('new_password_add')));
            } else {
                if (this.password.password_confirmation !== '') {
                    this.$refs.passwordform.validateField('password_confirmation');
                }
                callback();
            }
        };
        var validatePass2 = (rule, value, callback) => {
            if (value === '') {
                callback(new Error(this.$t('new_password_add')));
            } else if (value !== this.password.password) {
                callback(new Error(this.$t('replay_password')));
            } else {
                callback();
            }
        };
        return {
            loading: false,
            change: true,
            img: '',
            imgData: '',
            PasModal: false,
            LogModal: false,
            errors: {
                login_have: false
            },
            obj: {
                profile_for_user: {
                    name: '',
                    surname: '',
                    middlename: '',
                    phone_number: '',
                    email: '',
                }
            },
            ObjRules: {
                profile_for_user: {
                    name: [{
                        required: true,
                        message: this.$t('please_enter_data'),
                        trigger: "change",
                    }, ],
                    surname: {
                        required: true,
                        message: this.$t('please_enter_data'),
                        trigger: "change",
                    },
                    middlename: {
                        required: true,
                        message: this.$t('please_enter_data'),
                        trigger: "change",
                    },
                    phone_number: [{
                            required: true,
                            message: this.$t('please_enter_data'),
                            trigger: "change",
                        },
                        {
                            min: 17,
                            message: this.$t('add_phone_number'),
                            trigger: 'change'
                        }
                    ],
                    email: {
                        type: 'email',
                        message: this.$t('add_phone_number'),
                        trigger: "change",
                    },
                }
            },
            login_name: {
                login_name: ''
            },
            LoginRules: {
                login_name: [{
                    required: true,
                    message: this.$t('add_new_login'),
                    trigger: "change",
                }, ]
            },
            password: {
                password: '',
                password_confirmation: ''
            },
            PasswordRules: {
                password: [{
                        validator: validatePass,
                        trigger: 'change'
                    },
                    {
                        required: true,
                        message: this.$t('new_password_add'),
                        trigger: "change",
                    },
                    {
                        min: 4,
                        message: this.$t('password_check_four'),
                        trigger: 'change'
                    }
                ],
                password_confirmation: [{
                        validator: validatePass2,
                        trigger: 'change'
                    },
                    {
                        required: true,
                        message: this.$t('replay_password'),
                        trigger: "change",
                    },
                ],
            }

        };
    },
    computed: {
        me() {
            return this.$store.state.me
        },
        role() {
            return this.$store.state.role;
        }
    },
    
    methods: {
        save() {
            this.$refs.userform.validate((valid) => {
                if (valid) {
                    this.loading = true
                    if (this.imageFile) {
                        this.obj.profile_for_user.image = this.imageFile
                    } else {
                        delete this.obj.profile_for_user.image
                    }
                    axios.patch(`/users/${this.me.id}/`, this.obj)
                        .then((response) => {
                            this._Get()
                            this.$root.$emit('ChangeImage')
                            this.change = true
                            this.$swal.fire({
                                icon: 'success',
                                title: this.$t('sucsess_chage'),
                                timer: 3000
                            });
                        })
                        .catch(e => {
                            this.$swal.fire({
                                icon: 'error',
                                title: this.$t('error_letter_redail'),
                                timer: 3000
                            });
                        })
                        .finally(() => {
                            this.loading = false
                        })
                }
            })

        },
        uploadImage(event) {
            this.img = URL.createObjectURL(event.target.files[0])
            const file = event.target.files[0];
            toBase64(file).then((resp) => {
                this.imageFile = resp
            });
        },
        _Get() {
            this.loading = true
            axios.get(`/users/${this.me.id}/`)
                .then((response) => {
                    delete response.data.profile_for_user.user
                    this.img = response.data.profile_for_user.image
                    this.obj = response.data;
                })
                .finally(() => {
                    this.loading = false
                })
        },
        changePas() {
            this.$refs.passwordform.validate((valid) => {
                if (valid) {
                    this.loading = true
                    axios.patch(`/users/${this.me.id}/`, {
                            password: this.password.password
                        })
                        .then((response) => {
                            this.PasModal = false
                            this.$router.push('/login')
                            this.$swal.fire({
                                icon: 'success',
                                title: this.$t('sucsess_chage'),
                                timer: 3000
                            });
                        })
                        .catch(e => {
                            this.$swal.fire({
                                icon: 'error',
                                title: this.$t('error_letter_redail'),
                                timer: 3000
                            });
                        })
                        .finally(() => {
                            this.loading = false
                        })
                }
            })
        },
        changeLog() {
            this.$refs.loginform.validate((valid) => {
                if (valid) {
                    axios.patch(`/users/${this.me.id}/`, this.login_name)
                        .then((response) => {
                            this.LogModal = false
                            this.$router.push('/login')
                            this.$swal.fire({
                                icon: 'success',
                                title: this.$t('sucsess_chage'),
                                timer: 3000
                            });
                        })
                        .catch(e => {
                            if (e.response.data.login_name && e.response.data.login_name[0] == 'False') {
                                this.errors.login_have = true
                            }
                        })
                        .finally(() => {
                            this.loading = false
                        })
                }
            })
        },

    },
    mounted() {
        if (this.me.id) {
            this._Get()
        }
    }
};
</script>

<style lang="scss" scoped>
.top-header {
    width: 100%;
    padding: 20px;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    background: #fff;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.top-header h1 {
    font-weight: 700;
    font-size: 25px;
    color: #004787 !important;
}

.title-second-header {
    font-size: 18px;
    font-weight: 600;
    color: #004787 !important;
}



.user-left {

    padding: 0px 20px;
    width: 220px;

}

.user-right {
    flex: 1;
    padding-left: 40px;
    min-width: 400px;
}

.user-show {
    padding: 20px 0px;
    max-width: 1000px;
    width: 100%;
    z-index: 10;

}

.user-img {
    width: 100px;
    min-width: 100px;
    height: 100px;
    min-height: 100px;
    border-radius: 50%;
    position: relative;
}

.user-img .img-content {
    width: 100px;
    min-width: 100px;
    height: 100px;
    min-height: 100px;
    border-radius: 50%;
    object-position: center;
    overflow: hidden;
}

.user-img .img-content img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.title-h {
    font-size: 25px;
    font-weight: 700;
}

.change-img {
    position: absolute;
    bottom: 0;
    right: 0;
    cursor: pointer;
}

.change-img button {
    position: relative;
}

.change-img button input {
    width: 100%;
    height: 100%;
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 3;
}

.user-names p.name {
    font-size: 16px;
    margin-top: 10px;
    text-align: center;
    font-weight: 600;
}

.second-title {
    font-size: 14px;
    font-weight: 500;
    text-align: center;
}

.role-user {
    font-size: 10px;
    font-weight: 600;
}

.change-button {
    padding: 0px 25px;
    background: #004787;
    color: #fff;
    border-radius: 15px;
    font-weight: 400;
    height: 48px !important;
    font-size: 14px;
    outline: none;
}

.border-div {
    padding: 20px 0px;
}

.border-div div {
    background: rgb(139, 139, 139);
    width: 100%;
    height: 1px;
}

.bg1 {
    position: absolute;
    opacity: 0.2;
    bottom: -60px;
    right: -150px;
    width: 60%;
}

.login-name {
    display: flex;
    justify-content: center;
}

.login-name p {
    background: #fff;
    // box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
    font-size: 12px;
    text-align: center;
    font-weight: 600;
    margin-bottom: 0px;
    margin-top: 5px;
    color: rgb(83, 214, 83);
}

.user-names-button {
    margin-top: 15px;
}

.user-names-button button {
    padding: 10px 15px;
    width: 100%;
    margin-top: 4px;
    font-size: 12px;
    font-weight: 600;
    border-radius: 15px;
    color: #fff; 
    background: #004787;

}
</style>
